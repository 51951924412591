import URL_ROUTE from '../constants/index';

const token = sessionStorage.getItem('token');

class Api {
    async login(email, password) {
        let data = {
            email,
            password,
            //ip
        };
        const query = await fetch(`${URL_ROUTE.LOGIN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async selectDB() {
        const query = await fetch(`${URL_ROUTE.SELECTDB}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async listStateForNumber() {
        const query = await fetch(`${URL_ROUTE.LISTSTATEFORNUMBER}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async verifyNumbersExcel(upload) {
        let formData = new FormData();
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.VERIRYNUMBEREXCEL}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            responseType: 'blob',
            body: formData
        });
        return query;
    }

    async verifyNumbersAsesorExcel(upload) {
        let formData = new FormData();
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.VERIRYNUMBERASESOREXCEL}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            responseType: 'blob',
            body: formData
        });
        return query;
    }

    async loadNumbersCreateExcel(upload, indicative, totalNumber, position, direction, includeNumber, project, interval, country, invalidNumber) {
        let formData = new FormData();
        formData.append('upload', upload);
        formData.append('indicative', indicative);
        formData.append('totalNumber', totalNumber);
        formData.append('position', position);
        formData.append('direction', direction);
        formData.append('includeNumber', includeNumber);
        formData.append('project', project);
        formData.append('interval', interval);
        formData.append('country', country);
        formData.append('invalidNumber', invalidNumber);

        console.log(position, "position");
        console.log(direction, "direction");
        console.log(project, "project");
        console.log(country, "country");
        console.log(invalidNumber, "invalidNumber");

        const query = await fetch(`${URL_ROUTE.LOADNUMBERSCREATEEXCEL}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            responseType: 'blob',
            body: formData
        });
        return query;
    }

    async loadNumbersStateCreateExcel(upload, indicative, totalNumber, position, direction, includeNumber, project, interval, state) {
        let formData = new FormData();
        formData.append('upload', upload);
        formData.append('indicative', indicative);
        formData.append('totalNumber', totalNumber);
        formData.append('position', position);
        formData.append('direction', direction);
        formData.append('includeNumber', includeNumber);
        formData.append('project', project);
        formData.append('interval', interval);
        formData.append('state', state);

        const query = await fetch(`${URL_ROUTE.LOADNUMBERSSTATECREATEEXCEL}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            responseType: 'blob',
            body: formData
        });
        return query;
    }

    async loadNumbersTrashExcel(upload) {
        let formData = new FormData();
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.LOADNUMBERSTRASHEXCEL}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            body: formData
        });
        return query;
    }

    async loadNumbersTipifyCalls(upload, tipify, project) {
        let formData = new FormData();
        formData.append('upload', upload);
        formData.append('tipify', tipify);
        formData.append('project', project);

        const query = await fetch(`${URL_ROUTE.LOADNUMBERSTIPIFYCALLS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            body: formData
        });
        return query;
    }

    async listNumbersTipifyCalls(tipify, nameTipify) {
        const data = {tipify: tipify != '' ? tipify : '-', nameTipify: nameTipify != '' ? nameTipify : '-'}
        const query = await fetch(`${URL_ROUTE.LISTNUMBERTIPIFYCALLS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query;
    }

    async filterNumbersTipifyCalls(tipify, nameTipify, dateStart, dateEnd) {
        const data = {tipify: tipify != '' ? tipify : '-', nameTipify: nameTipify != '' ? nameTipify : '-', dateStart, dateEnd}
        const query = await fetch(`${URL_ROUTE.FILTERNUMBERTIPIFYCALLS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query;
    }

    async loadNumbers(project, state, totalNumber, totalPhone, position, indicative) {
        let data = { project, state, totalNumber, totalPhone, position, indicative}
        const query = await fetch(`${URL_ROUTE.LOADNUMBERS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query;
    }

    async loadNumbersvii(totalNumber, totalPhone, position, indicative, date_start, date_end) {
        let data = { totalNumber, totalPhone, position, indicative, date_start, date_end }
        const query = await fetch(`${URL_ROUTE.LOADNUMBERSVII}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            /* responseType: 'blob', */
            body: JSON.stringify(data)
        });
        return query;
    }

    async downloadExcelNumber(phones) {
        let data = { phones}
        const query = await fetch(`${URL_ROUTE.DOWNLOADEXCELNUMBER}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query;
    }

    async downloadExcelNumberTipifyCalls(details) {
        let data = { details}
        console.log(data, "data");
        const query = await fetch(`${URL_ROUTE.DOWNLOADEXCELNUMBERTIPIFYCALLS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query;
    }

    async viewListLog() {
        //let data = { project}
        const query = await fetch(`${URL_ROUTE.VIEWLISTLOG}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(data)
        });
        return query;
    }

    async listTrashNumber() {
        const query = await fetch(`${URL_ROUTE.LISTTRASHNUMBER}`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
        });
        return query;
    }

    async createuser(email, name, lastname, password, idprofile) {
        let data = { email, name, lastname, password, idprofile }
        const query = await fetch(`${URL_ROUTE.CREATEUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listusers() {
        const query = await fetch(`${URL_ROUTE.LISTUSERS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listLogsBackups() {
        const query = await fetch(`${URL_ROUTE.LISTLOGSBACKUPS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listDBBackups() {
        const query = await fetch(`${URL_ROUTE.LISTDBBACKUPS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async createBdBackups(host, port, database, username, password, project, type, uuid) {
        let data = { host, port, database, username, password, project, type, uuid }
        const query = await fetch(`${URL_ROUTE.CREATEBDBACKUPS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async backupDatabase() {
        const query = await fetch(`${URL_ROUTE.BACKUPDATABASE}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async totalSales(policytype, uuid) {
        let data = { policytype, uuid }
        const query = await fetch(`${URL_ROUTE.TOTALSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesRenew(uuid) {
        let data = { uuid }
        const query = await fetch(`${URL_ROUTE.TOTALSALESRENEW}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgents(date, policytype, uuid) {
        let data = { date, policytype, uuid }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

}

export default new Api();